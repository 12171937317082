import request from "@/utils/request";

// 登录方法
export function login() {
  return request({
    url: "/auth/login",
    method: "post",
  });
}

// 退出方法
export function logout() {
  return request({
    url: "/auth/logout",
    method: "post",
  });
}
