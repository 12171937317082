import request from '@/utils/request'

// 获取用户详细信息
export function getInfo() {
    return request({
        url: '/system/user',
        method: 'get'
    })
}

export function listMaster() {
    return request({
      url: `/system/user/list`,
      method: "get",
    });
  }