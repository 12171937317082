<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    console.log(process.env.VUE_APP_VER);
  },
};
</script>
<style lang="scss" scoped></style>
