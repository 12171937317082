import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

import CmsLayout from "@/layout/Cms.vue";

const routes = [
  {
    path: "/login",
    component: () => import("@/views/Login"),
  },
  {
    path: "/logout",
    component: () => import("@/views/Logout"),
  },
  {
    path: "/callback",
    component: () => import("@/views/Callback"),
  },
  {
    path: "/test",
    component: () => import("@/views/test/Index.vue"),
  },

  // import ShowRoom from "@/components/ShowRoom";
  // import EnterpriseDetails from "@/components/EnterpriseDetails";
  {
    path: "/",
    component: CmsLayout,
    redirect: "/cms/home",
    children: [
      {
        path: "/cms/home",
        component: () => import("@/views/cms/home/Index.vue"),
      },
      {
        path: "/cms/goods",
        component: () => import("@/views/cms/goods/Index.vue"),
      },
      {
        path: "/cms/company",
        component: () => import("@/views/cms/company/Index.vue"),
      },
      {
        path: "/cms/company/show/:id",
        component: () => import("@/views/cms/company/Show.vue"),
      },
      {
        path: "/cms/goods/show/:id",
        component: () => import("@/views/cms/goods/Show.vue"),
      },
      {
        path: "/cms/room/:id",
        component: () => import("@/views/cms/room/Index.vue"),
      },
      {
        path: "/cms/offline",
        component: () => import("@/views/cms/offline/Index.vue"),
      },
      {
        path: "/cms/about",
        component: () => import("@/views/cms/about/Index.vue"),
      },
    ],
  },
  {
    path: "/cms/player/:eid",
    component: () => import("@/views/cms/player/Index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
