<template>
  <div class="footer">
    <!-- <div class="footerCon">
		<img src="/images/images/二维码.jpg"/>
	</div> -->
    <div class="copy">
      Copyright &copy; 2018 - 2024 新疆维吾尔自治区科学技术协会
      <div>2024新疆农业机械博展会</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>
<style scoped>
.footer {
  overflow: hidden;
  width: 100%;
  /* height: 100%; */
  /* min-height: 300px; */
  /* line-height: 300px; */
  color: #fff;
  /* background: #474747; */
  background: linear-gradient(180deg, #538eed 0%, #71b0fa 100%);
  /* overflow: hidden;
  text-align: center; */
}
.footerCon {
  max-width: 1300px;
  text-align: right;
  margin: auto;
  padding: 30px 0;
}
.footerCon img {
  display: inline-block;
}
.logo {
  width: 95px;
  height: 45px;
  margin: 50px auto 20px;
}
.title {
  font-size: 25px;
  margin-bottom: 20px;
}
.address_tel_fax {
  color: #d3d3d3;
  font-size: 14px;
  margin: 10px 0;
}
.email_wx {
  color: #d3d3d3;
  font-size: 14px;
}
.copy {
  color: #fff;
  font-size: 14px;
  margin: 50px 0 50px;
  text-align: center;
  line-height: 30px;
}
@media screen and (max-width: 997px) {
  .title {
    font-size: 20px;
  }
  .address_tel_fax {
    font-size: 12px;
  }
  .email_wx {
    font-size: 12px;
  }
  .copy {
    font-size: 12px;
    margin: 30px 0 10px;
  }
}
</style>
