import { login } from "@/api/auth";
import { getToken, setToken, removeToken } from "@/utils/auth";

const auth = {
  state: {
    token: getToken(),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
  },
  actions: {
    // 登录
    login({ commit }) {
      return new Promise((resolve, reject) => {
        login()
          .then((res) => {
            setToken(res.token);
            commit("SET_TOKEN", res.token);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    // 退出系统
    logout({ commit }) {
      removeToken();
      commit("SET_TOKEN", "");
    },
  },
};

export default auth;
