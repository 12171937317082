import { getRouters } from "@/api/system/navigation";

const navigation = {
  state: {
    sidebarRouters: [],
  },
  mutations: {
    SET_ROUTES: (state, routes) => {
      state.sidebarRouters = routes;
    },
  },
  actions: {
    // 生成路由
    generateRoutes({ commit }) {
      return new Promise((resolve) => {
        // 向后端请求路由数据
        getRouters().then((res) => {
          let routes = filterAsyncRouter(res);
          commit("SET_ROUTES", routes);
          resolve(routes);
        });
      });
    },
  },
};

function filterAsyncRouter(asyncRouterMap) {
  // 动态添加可访问路由表
  let rewriteRoutes = [];
  for (let item of asyncRouterMap) {
    let route = preRoute(item);
    rewriteRoutes.push(route);

    addChild(item, route);
  }

  return rewriteRoutes;
}

function addChild(parentItem, parentRoute) {
  if (parentItem.children.length == 0) {
    return;
  }
  parentRoute.children = [];

  for (let item of parentItem.children) {
    let route = preRoute(item);
    parentRoute.children.push(route);

    addChild(item, route);
  }
}

function preRoute(item) {
  let route = {
    name: `Id_${item.id}`,
    url: item.routeUrl,
    path: formatUrl(item.routePath),
    meta: {
      title: item.routeText,
      icon: item.iconName,
      display: !item.hidden,
    },
  };

  let jsonConfig = {};
  if (item.jsonConfig) {
    jsonConfig = JSON.parse(item.jsonConfig);
    Object.assign(route, jsonConfig);
  }

  if (jsonConfig.component) {
    route.component = loadLayout(jsonConfig.component);
  } else {
    route.component = loadView(item.routeVue);
  }

  return route;
}

function formatUrl(routePath) {
  if (routePath) {
    return routePath;
  }
  //如果是空就随机一个数字
  return `/ra504fcd9c/${Math.random()}`.replace(".", "");
}

function loadView(routeVue) {
  // 路由懒加载
  return () => import(`@/views${routeVue}`);
}

function loadLayout(url) {
  // 路由懒加载
  return () => import(`@/layout/${url}`);
}

export default navigation;
