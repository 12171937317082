import axios from "axios";
import store from "@/store";
import qs from "qs";

import { Message } from "element-ui";
import { getToken } from "@/utils/auth";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 10000,
});

// request拦截器
service.interceptors.request.use(
  (config) => {
    if (config.method === "get") {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2
      config.paramsSerializer = function (params) {
        return qs.stringify(params, { arrayFormat: "repeat" });
      };
    }

    const token = getToken();
    if (token) {
      // 判断是否存在token，如果存在的话，则每个http header都加上token
      //config.headers.authorization = token //请求头加上token

      config.headers["Authorization"] = "Bearer " + token; // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config;
  },
  (error) => {
    console.log(error);
    Promise.reject(error);
  }
);

// 响应拦截器
service.interceptors.response.use(
  (res) => {
    return res.data;
  },
  (error) => {
    switchErr(error);
    return Promise.reject(error);
  }
);

function switchErr(error) {
  console.log(error);
  let status = error.response ? error.response.status : 0;
  if (status == 401) {
    Message.error("登录状态已过期，您需要重新登录");

    store.dispatch("logout");
    store.dispatch("clearInfo");

    location.href = process.env.VUE_APP_LOGIN_URL;
  } else if (status == 400) {
    let message = formatErrMsg(error.response.data);
    Message.error(`请求错误，${message}`);
  } else if (status == 500) {
    let message = error.response.data.error
      ? error.response.data.error
      : error.response.data;
    Message.error(`操作失败，${message}`);
  } else {
    let { message } = error;

    Message({
      message: "系统接口错误," + message + "。请您刷新重试。",
      type: "error",
      duration: 5 * 1000,
    });
  }
}

function formatErrMsg(data) {
  if (!data.errors) {
    return;
  }

  let errMsg = data.title;
  if (!errMsg) {
    errMsg = "";
  }
  for (let item in data.errors) {
    errMsg += data.errors[item][0];
  }

  errMsg = errMsg.replace("One or more validation errors occurred.", "");
  return errMsg;
}

export default service;
