import router from "./router";
import store from "./store";

import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style

import { getToken } from "@/utils/auth";
import getPageTitle from "@/utils/get-page-title";

NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ["/login", "/callback", "/logout", "/test"];

router.beforeEach((to, from, next) => {
  // set page title
  NProgress.start();
  document.title = getPageTitle(to.meta.title);

  filter(to, from, next);
});

router.afterEach(() => {
  NProgress.done();
});

function filter(to, from, next) {
  if (getToken()) {
    getRoles(to, next);
  } else {
    login(to, next);
  }
}

function getRoles(to, next) {
  if (store.getters.userNo) {
    next();
  } else {
    //store.getters.roles.length == 0
    store
      .dispatch("getInfo")
      .then(() => {
        store
          .dispatch("generateRoutes")
          .then((routers) => {
            addRoute(routers);
            //没有权限 next(`/401`)
            next({ ...to, replace: true }); // hack方法 确保addRoutes已完成
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        alert(err);
      });
  }
}

function login(to, next) {
  // 没有token
  if (to.path.startsWith("/cms/")) {
    next();
  } else {
    if (whiteList.indexOf(to.path) > -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next(`/login?redirect=${to.fullPath}`); // 否则全部重定向到登录页
    }
  }
}

function addRoute(routers) {
  for (let item of routers) {
    router.addRoute(item);
  }
  //router.addRoute({ path: '*', redirect: '/404', hidden: true })
}
