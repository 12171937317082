import Vue from "vue";
import Vuex from "vuex";

import app from "./modules/app";
import auth from "./modules/auth";
import user from "./modules/user";
import navigation from "./modules/navigation";
import settings from "./modules/settings";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    settings,
    app,
    auth,
    user,
    navigation,
  },
  getters: {
    userNo: (state) => state.user.userNo,
    fullName: (state) => state.user.fullName,
    deptName: (state) => state.user.deptName,

    sidebar: (state) => state.app.sidebar,
    device: (state) => state.app.device,
    sidebarRouters: (state) => state.navigation.sidebarRouters,
  },
});
