import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "@/permission";
import "@/styles/index.scss"; // global css

import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";

import moment from "moment";

import BaiduMap from "vue-baidu-map";

Vue.use(BaiduMap, {
  ak: "0dtyc39E9VfnSM34A35buULuXo5yjB4H",
});

Vue.config.productionTip = false;
Vue.use(ElementUI);

Vue.prototype.msgSuccess = function (msg = "操作成功!", onClose = null) {
  this.$message({
    showClose: true,
    message: msg,
    type: "success",
    duration: 1500,
    onClose: onClose,
  });
};

Vue.prototype.msgError = function (msg, onClose = null) {
  if (!msg) {
    msg = "操作失败!";
  }
  this.$message({
    showClose: true,
    message: msg,
    type: "error",
    onClose: onClose,
  });
};

Vue.prototype.msgInfo = function (msg) {
  this.$message.info(msg);
};

Vue.prototype.isMobile = function () {
  let flag = navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
  return flag != null;
};

Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
