<template>
  <div v-loading="loading">
    <Header></Header>
    <div class="container">
      <router-view />
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Footer from "@/components/Cms/Footer.vue";
import Header from "@/components/Cms/Header.vue";

export default {
  data() {
    return {
      loading: false,
    };
  },
  components: {
    Header,
    Footer,
  },
  methods: {},
};
</script>

<style scoped lang="scss">
.container {
  // max-width: 1260px;
  // margin: auto;
}
</style>
