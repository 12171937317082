import { getInfo } from "@/api/system/user";

const user = {
  state: {
    userNo: "",
    fullName: "",
    deptName: "",
  },
  mutations: {
    SET_USERNO: (state, userNo) => {
      state.userNo = userNo;
    },
    SET_FULLNAME: (state, fullName) => {
      state.fullName = fullName;
    },
    SET_DEPTNAME: (state, deptName) => {
      state.deptName = deptName;
    },
  },

  actions: {
    // 获取用户信息
    getInfo({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo()
          .then((res) => {
            commit("SET_USERNO", res.userNo);
            commit("SET_FULLNAME", res.fullName);
            commit("SET_DEPTNAME", res.deptName);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    clearInfo({ commit }) {
      commit("SET_USERNO", "");
      commit("SET_FULLNAME", "");
    },
  },
};

export default user;
