<template>
  <!-- 头部整体盒子 -->
  <div id="header" :class="home ? 'home' : ''">
    <div class="top">
      <div class="topCom">
        <div class="logoText">
          <strong>新疆农业机械博览会</strong>
          Agricultural machinery expo
        </div>
        <div v-if="fullName">
          <a class="btn" @click="handleAdmin" target="进入我的企业管理"
            ><b>{{ deptName }}[{{ fullName }}]</b>
          </a>
        </div>
        <div v-else>
          <a
            class="btn btn1"
            href="http://kjgzzxt.xast.org.cn//TechWeb/ControlRegister/DeptManager?lastModuleId=90"
            >注册</a
          >
          <a class="btn" @click="handleLogin">登录</a>
        </div>
      </div>
    </div>
    <!-- 电脑导航 -->
    <div class="header-nav">
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <li
          v-for="item in navList"
          :key="item.name"
          :class="item.path == pathname ? 'active' : ''"
        >
          <router-link :to="item.path">
            {{ item.name }}
          </router-link>

          <!-- <a :href="item.path">{{ item.name }}</a> -->
        </li>
      </ul>
    </div>
    <!-- 手机导航 -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

import "@/styles/header.scss";

export default {
  data() {
    return {
      home: false,
      pathname: "",
      navList: [
        {
          name: "首页",
          path: "/cms/home",
        },

        {
          name: "展品与服务",
          path: "/cms/goods",
        },
        {
          name: "企业展示",
          path: "/cms/company",
        },

        {
          name: "展位展厅 ",
          path: "/cms/room/17",
        },
        {
          name: "线下展厅",
          path: "/cms/offline",
        },
        {
          name: "联系我们 ",
          path: "/cms/about",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["fullName", "deptName"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler() {
        this.pathname = location.pathname;
      },
    },
  },
  methods: {
    handleLogin() {
      location.href = process.env.VUE_APP_LOGIN_URL;
    },
    handleAdmin() {
      this.$router.push("/admin");
    },
  },
  created() {},
};
</script>
<style scoped></style>
